import { CreateArticleRequestData, Settings } from 'api';
import { FormField, InputElement } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { ArticleType, OptionKey, SystemLanguage } from 'enum/api';
import { parse as mdParser } from 'marked';
import { DefaultValues } from 'react-hook-form';
import { RequiredNotNullable } from 'types';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { CreateArticleFormData } from './CreateArticle.types';

export const createArticleFormSchema = {
  fields: {
    [FormField.ArticleType]: {
      type: InputElement.Select,
      translationKey: 'article_type',
      getPlaceholder: () => 'Type',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.ArticleType, t, settings)
    },
    [FormField.Language]: {
      type: InputElement.Select,
      translationKey: 'system_language',
      getPlaceholder: () => 'Language',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.SystemLanguage, t, settings)
    },
    [FormField.Code]: {
      type: InputElement.Input,
      translationKey: 'code',
      hintTranslationKey: 'code_input',
      getPlaceholder: () => 'Code'
    },
    [FormField.Domains]: {
      type: InputElement.MultiSelect,
      translationKey: 'domains',
      getPlaceholder: () => 'Domains',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.DomainName, t, settings)
    },
    [FormField.Title]: {
      type: InputElement.Input,
      translationKey: 'title',
      getPlaceholder: () => 'Title',
      maxLength: 100
    },
    [FormField.TabTitle]: {
      type: InputElement.Input,
      translationKey: 'tab_title',
      getPlaceholder: () => 'Tab title',
      maxLength: 100
    },
    [FormField.SubTitle]: {
      type: InputElement.Input,
      translationKey: 'subtitle',
      getPlaceholder: () => 'Subtitle',
      maxLength: 100
    },
    [FormField.MetaDescription]: {
      type: InputElement.Input,
      translationKey: 'meta_description',
      getPlaceholder: () => 'Meta description',
      maxLength: 160
    },
    [FormField.Message]: {
      type: InputElement.MdEditor,
      translationKey: 'article_message',
      getPlaceholder: () => 'Article message',
      minHeight: 36
    },
    [FormField.KeyWords]: {
      type: InputElement.MultiSelect,
      translationKey: 'key_words',
      getPlaceholder: () => 'Key words',
      isSearchable: true,
      shouldHideOptions: true,
      canAdd: true
    },
    [FormField.GeneralArticle]: {
      type: InputElement.Toggle,
      translationKey: 'general_article',
      getPlaceholder: () => 'General article'
    },
    [FormField.Countries]: {
      type: InputElement.MultiSelect,
      isSearchable: true,
      translationKey: 'country',
      getPlaceholder: () => 'Country',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.Country, t, settings)
    },
    [FormField.Images]: {
      type: InputElement.UploadFile,
      fileLimit: 1
    },
    [FormField.PreviewImages]: {
      type: InputElement.UploadFile,
      fileLimit: 1
    },
    [FormField.ShowOnHomeScreen]: {
      type: InputElement.Toggle,
      translationKey: 'show_on_home_screen',
      getPlaceholder: () => 'Show on home screen'
    },
    [FormField.Order]: {
      type: InputElement.NumberInput,
      translationKey: 'order',
      getPlaceholder: () => 'Order (optional)',
      min: 0,
      max: 100
    }
  },
  gridLayout: {
    templateAreas: `
      "${FormField.ArticleType} ."
      "${FormField.Language} ."
      "${FormField.Code} ."
      "${FormField.Domains} ."
      "${FormField.Title} ."
      "${FormField.TabTitle} ."
      "${FormField.SubTitle} ${FormField.SubTitle}"
      "${FormField.MetaDescription} ${FormField.MetaDescription}"
      "${FormField.Message} ${FormField.Message}"
      "${FormField.KeyWords} ${FormField.KeyWords}"
      "${FormField.GeneralArticle} ."
      "${FormField.Countries} ${FormField.Countries}"
      "${FormField.Images} ."
      "${FormField.ShowOnHomeScreen} ."
      "${FormField.Order} ."
      "${FormField.PreviewImages} ."
    `,
    gridTemplateColumns: '1fr 1fr',
    rowGap: 6,
    columnGap: 10
  }
} as const;

export const createArticleValidationSchema: yup.SchemaOf<CreateArticleFormData> =
  yup.object({
    [FormField.ArticleType]: yup.mixed().oneOf(getAllEnumValues(ArticleType)),
    [FormField.Language]: yup.mixed().oneOf(getAllEnumValues(SystemLanguage)),
    [FormField.Code]: yup
      .string()
      .required()
      .matches(/^[a-z0-9-]+$/, 'invalid_code')
      .min(3)
      .max(50),
    [FormField.Domains]: yup.array(yup.string().required()).required().min(1),
    [FormField.Title]: yup.string().required().max(100),
    [FormField.TabTitle]: yup.string().required().max(100),
    [FormField.SubTitle]: yup.string().required().max(100),
    [FormField.MetaDescription]: yup.string().required().max(160),
    [FormField.Message]: yup.string().required().max(30000),
    [FormField.KeyWords]: yup.array(yup.string().required()).defined(),
    [FormField.GeneralArticle]: yup.boolean().defined(),
    [FormField.Countries]: yup.array(yup.string().required()).min(1),
    [FormField.ShowOnHomeScreen]: yup.boolean().defined(),
    [FormField.Order]: yup.number().integer().optional(),
    [FormField.Images]: yup
      .array(
        yup
          .object()
          .shape({
            file: yup.mixed().required(),
            url: yup.string().required()
          })
          .required()
      )
      .min(1),
    [FormField.PreviewImages]: yup
      .array(
        yup
          .object()
          .shape({
            file: yup.mixed().required(),
            url: yup.string().required()
          })
          .required()
      )
      .optional()
  });

export const getDefaultFormValues = (
  settings: Settings
): DefaultValues<CreateArticleFormData> => ({
  [FormField.ArticleType]: ArticleType.Blog,
  [FormField.Language]: SystemLanguage.EN,
  [FormField.Code]: '',
  [FormField.Domains]: [],
  [FormField.Title]: '',
  [FormField.TabTitle]: '',
  [FormField.SubTitle]: '',
  [FormField.MetaDescription]: '',
  [FormField.Message]: '',
  [FormField.Images]: [],
  [FormField.PreviewImages]: [],
  [FormField.KeyWords]: [],
  [FormField.GeneralArticle]: false,
  [FormField.Countries]: settings[OptionKey.Country],
  [FormField.ShowOnHomeScreen]: false,
  [FormField.Order]: undefined
});

export const serializeRequestData = (
  formData: CreateArticleFormData
): CreateArticleRequestData => {
  const data = formData as RequiredNotNullable<CreateArticleFormData>;

  return {
    type: data[FormField.ArticleType],
    language: data[FormField.Language],
    code: data[FormField.Code],
    domains: data[FormField.Domains],
    title: data[FormField.Title],
    tabTitle: data[FormField.TabTitle],
    subtitle: data[FormField.SubTitle],
    metaDescription: data[FormField.MetaDescription],
    text: mdParser(data[FormField.Message]),
    keywords: data[FormField.KeyWords],
    general: data[FormField.GeneralArticle],
    countries: data[FormField.Countries],
    showOnHomeScreen: data[FormField.ShowOnHomeScreen],
    order: Number(data[FormField.Order]),
    image: data[FormField.Images].map(({ file }) => file),
    previewImage: data[FormField.PreviewImages].map(({ file }) => file)
  };
};
